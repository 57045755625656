/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */
/* Color Theme Swatches in Hex */
$color-theme_logo-colorful-1-hex: #8704BF;
$color-theme_logo-colorful-2-hex: #BB77D9;
$color-theme_logo-colorful-3-hex: #BAD94A;
$color-theme_logo-colorful-4-hex: #F2CD13;
$color-theme_logo-colorful-5-hex: #F27B35;

/* Color Theme Swatches in RGBA */
$dark-purple: rgba(135,4,191, 1);
$purple: rgba(187,119,217, 1);
$lime: rgba(186,217,74, 1);
$yellow: rgba(242,205,19, 1);
$orange: rgba(242,123,53, 1);
$blue: rgba(13,152,195, 1);


.guide {
  border: solid #F8D432 1px;
}

html, body{
  height: 100%;
  padding-top: 20px;
}

body {
  color: #5a5a5a;
}


.loginarea {background:#352A40 url("./assets/africa-lady-new.jpg") no-repeat center;
  background-size: cover}
.bg-indigo {background: #BAD94A;}
.bg-deep-deep-purple {
  background-color: $blue;
}


.display-text-purple {
  color: $purple;
  font-size: 3.8em;
  font-weight: bolder;
  line-height: 1.2;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: bolder;
  line-height: 1.2;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: bolder;
  line-height: 1.2;
}

.display-6 {
  font-size: 1.3em;
  font-weight: bolder;
  line-height: 1.2;
}

.display-item-header {
  font-size: 1.3em;
  font-weight: bolder;
  line-height: 1.2;
}

.display-x {
  font-size: 1.8em;
  font-weight: bolder;
  line-height: 1.2;
}

.card-title {
  margin-bottom: 0.75rem;
  height: 65px !important;
}

footer {
  background: rgba(240,242,242,.2);
}

.product_description {
  font-size: .85rem;
}

.height-300 {
  height: 300px;

}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  float: right;
  //list-style: none;
}

.btn-custom {
  color: #fff;
  //background-color: $dark-purple;
  background-color: $dark-purple;
  //border-color: #28a745;
}

.btn-custom:hover {
  color: #fff;
  background-color: $purple;
  //border-color: #1e7e34;
}

.btn-custom:focus, .btn-custom.focus {
  color: #fff;
  background-color: $dark-purple;
  //border-color: #1e7e34;
  //box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn {
  border-radius: 0.15rem;
  //transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge {
  border-radius: 0.08rem;
}
